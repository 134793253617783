import React from 'react'
import PropTypes from 'prop-types'
import sponsors from '../data/sponsors.json'
import Ticker from 'react-ticker'

class Sponsor extends React.Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			sponsorString: '',
		}
	}

	componentDidMount() 
	{
		let sponsorHtml = ''
		sponsors.forEach((sponsor) =>
		{
			sponsorHtml += sponsor.name + ' '

		})

		this.setState({sponsorString: sponsorHtml})
	}

	render()
	{
		return (
			<footer id="footer" className={sponsors.length === 0 ? 'd-none' : '' + 'content-footer footer bg-primary w-100 py-3'}>

				<Ticker>
					{({ index }) => (
						<>
							<h3 className="footer-text fw-bold" data-index={{index}}>{this.state.sponsorString}</h3>
						</>
					)}
				</Ticker>
			</footer>
		)
	}
}

Sponsor.propTypes = {
	json: PropTypes.object,
	id: PropTypes.string,
}

export default Sponsor
